import React from 'react';
import BaseSelect from '../select'; 
import { 
    IBaseSelectProps, 
    ISelectOptions 
} from '../select/types';

interface IGenderInputProps extends IBaseSelectProps {
    forwardedRef: any
}

class GenderInput extends React.Component<IGenderInputProps> {
    static defaultProps: Partial<IGenderInputProps>;

    private options: ISelectOptions[] = [
        { value: 'F', label: 'Feminino' },
        { value: 'M', label: 'Masculino' }
    ]

    constructor(props: IGenderInputProps) {
        super(props);
        
        if (props.options)
            this.options = props.options;
    }

    render() {
        const { 
            props, 
            options
        } = this,
        {
            forwardedRef 
        } = props;

        return (
            <BaseSelect
                {...props}
                options={options}
                ref={forwardedRef}
            />
        );
    }
}

GenderInput.defaultProps = {
    label: 'Sexo',
    type: 'string'
}

export default React.forwardRef<BaseSelect, Omit<Partial<IBaseSelectProps>, 'type'>>((props, ref) => {
    return <GenderInput {...props} forwardedRef={ref} />
});