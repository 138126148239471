import React, { RefObject } from "react";
import { Col } from "react-bootstrap";
import { faCalendarAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import DatePicker, {
  registerLocale,
  ReactDatePickerProps
} from "react-datepicker";
import "./style.scss";

import ptBR from "date-fns/locale/pt-BR";
import BaseInput from "../input";
import { ICommonInputProps } from "../base";
import Util from "../../util";
registerLocale("ptBR", ptBR);

export const DATE_MASK = [
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export interface IDateInputProps extends ICommonInputProps, Partial<Omit<ReactDatePickerProps, "name" | "onBlur" | "onChange">> {
  col?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  callbackChange?(date: Date | null): void | undefined;
  forwardedRef?: any;
  showYearPicker?: boolean;
}

class DateInput extends React.Component<IDateInputProps>
{
  static defaultProps: IDateInputProps;

  state = {
    date: null
  };
  
  handleChange = (date: Date | null) =>
  {
    let instance = this.props.forwardedRef,
        isValid = this.checkIfIsValid(date);

    if (instance && instance.current)
    {
      if (isValid)
      {
        instance.current.setValue(date ? Util.dateISOToDate(date) : date, date);
        instance.current.setIsInvalid(false);
      }
      else
        instance.current.setValue(null, null);
    }

    this.setState(
      () => ({ date: isValid ? date : null }),
      () => this.props.callbackChange && this.props.callbackChange(date)
    );
  };
 
  onBlur = () =>
  {
    let date = this.state.date,
        instance = this.props.forwardedRef,
        isValid = this.checkIfIsValid(date);

    if (!date)
      return null;

    if (instance && instance.current)
    {
      if (isValid)
      {
        instance.current.setValue(date ? Util.dateISOToDate(date) : date, date);
        instance.current.setIsInvalid(false);
      }
      else
        instance.current.setValue(null);
    }

    this.setState({ date: isValid ? date : null },
                  () => this.props.callbackChange && this.props.callbackChange(date));
  };

  checkIfIsValid = (date: Date | null) =>
  {
    const { minDate, maxDate } = this.props;

    if (date && minDate) if (date! < minDate!) return false;

    if (date && maxDate) if (date! > maxDate!) return false;

    return true;
  };

  render()
  {
    const { col, forwardedRef, label, name, id } = this.props;

    return (
      <Col xs={col!.xs} sm={col!.sm} md={col!.md} lg={col!.lg} xl={col!.xl}>
        <DatePicker
          {...this.props}
          selected={this.state.date}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          customInput={
            <CustomDateInput
              {...this.props}
              id={id}
              name={name}
              label={label}
              handleChange={this.handleChange}
              innerRef={forwardedRef}
            />
          }
        />
      </Col>
    );
  }
}

DateInput.defaultProps = {
  label: "Data",
  name: "date",
  id: "datepicker",
  col: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6
  },
  // minDate: null,
  // locale: "ptBR",
  // dateFormat: "dd/MM/yyyy"
};

interface ICustomDateInput extends ICommonInputProps
{
  handleChange(date: Date | null | string): void | undefined;
  innerRef?: RefObject<any>;
}

export class CustomDateInput extends React.Component<ICustomDateInput>
{
  render()
  {
    const { onClick, handleChange, innerRef } = this.props;

    return (
      <BaseInput
        {...this.props}
        autoComplete="off"
        prepend={{
          onClick: onClick,
          buttonProps: {
            variant: "primary"
          },
          icon: faCalendarAlt,
          hideWhenRead: true
        }}
        append={{
          onClick: () => handleChange(""),
          buttonProps: {
            variant: "secondary"
          },
          icon: faTrash,
          hideWhenRead: true
        }}
        //mask={DATE_MASK}
        ref={innerRef}
        isDate
        onReset={() => handleChange("")}
      />
    );
  }
}

export default React.forwardRef<BaseInput, Partial<Omit<IDateInputProps, "forwardedRef">>>((props, ref) => {
  return <DateInput {...props} forwardedRef={ref} />;
});