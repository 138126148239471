import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ErrorBoundary from '../error-boundary';
import { UserProvider } from '../context/user';
import Util from '../util';
import AuthService from '../../services/auth-service';
import PermissionService from '../../services/permission-service';
import Loading from '../loading';

/**
 * Propriedades da classe BaseScreen
 */
export interface IBaseScreenProps {
    /** Título da tela */
    title: string,
    /** Descrição da tela para maiores detalhes */
    description?: string
}

/**
 * Componente funcional BaseScreen
 * Toda a tela deve ser incorporada dentro deste componente, pois ele está encarregado
 * de carregar todas as permissões, id do usuário, id do módulo e token
 * @param props Propriedades dee configuração componente BaseScreen
 */
const BaseScreen: FunctionComponent<IBaseScreenProps> = (props) => {
    const [permissionIdList, setPermissionIdList] = useState<Array<string>>([]),
          [loading, setLoading] = useState<boolean>(true),
          userId: number = parseInt(Util.getUrlParams().get("p_id_usuario") || '0'),
          moduleId: number = parseInt(Util.getUrlParams().get("p_id_modulo") || '0'),
          token: string = Util.getUrlParams().get("token") || '';
    
    useEffect(() => {
        if (token) AuthService.setAuthToken(token);

        if (userId && moduleId) {
            setLoading(true);

            PermissionService.get(userId, moduleId).then(value => {
                const { data, status } = value;
        
                if (status === 200)
                    setPermissionIdList(data.data);
                else {
                    setPermissionIdList([]);
                    throw new Error(
                        'Erro ao carregar as permissões'
                    );
                }

                setLoading(false);
            }, () => setLoading(false));
        } 
        else
            setLoading(false);
    }, [userId, moduleId, token]);

    const render = loading ?
        <Loading hidden={false} /> :
        <ErrorBoundary>
            <UserProvider 
                value={{ 
                    userId,
                    moduleId,
                    token,
                    permissionIdList, 
                    checkIfHasPermission: Util.checkIfValueExistsInList
                }}
            >
                <Container fluid className="bg-light p-2">
                    <h4>
                        {props.title}
                        {
                            props.description && 
                            <small className="text-muted lead"> - {props.description}</small>
                        }
                    </h4>
                    {props.children}
                </Container>
            </UserProvider>
        </ErrorBoundary>;

    return render;
}

export default BaseScreen;