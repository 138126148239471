import React from 'react';

/**
 * Propriedades do contexto do formulário
 */
export interface IFormContextProps {
    name: string,
    scopePath: string,
    registerField?(field: React.RefObject<any>): void
}

/**
 * Instância do contexto (inicialização)
 */
const FormContext = React.createContext<IFormContextProps>({} as IFormContextProps)

/**
 * Instância do contexto para fornecer os dados
 */
export const FormProvider = FormContext.Provider

/**
 * Instância do contexto para consumir os dados
 */
export const FormConsumer = FormContext.Consumer

/**
 * Instância geral do contexto para fornecer e consumir os dados
 */
export default FormContext