import React from "react";
import BaseInput, { IBaseInputProps } from '../input';

export const CNPJ_MASK = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

interface ICnpjInputProps extends IBaseInputProps {
    forwardedRef: any
}

class CnpjInput extends React.Component<ICnpjInputProps> {
    static defaultProps: Partial<ICnpjInputProps>;

    handleBlur = (event: any) => {
        let value = event.target.value,
            isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {
            let instance = forwardedRef.current;
            
            instance.setValue(value);

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else
                instance.setIsInvalid(false);
        }
    }

    checkIfIsValid = (value: any) => {
        let cnpj = value.replace(/[^\d]+/g, '')

        if (cnpj.length !== 14)
            return false

        if (/^(\d)\1+$/.test(cnpj))
            return false

        let t = cnpj.length - 2,
            d = cnpj.substring(t),
            d1 = parseInt(d.charAt(0)),
            d2 = parseInt(d.charAt(1)),
            calc = (x: number) => {
                let n = cnpj.substring(0, x),
                    y = x - 7,
                    s = 0,
                    r = 0

                    for (let i = x; i >= 1; i--) {
                        s += n.charAt(x - i) * y--;
                        if (y < 2)
                            y = 9
                    }

                    r = 11 - s % 11
                    return r > 9 ? 0 : r
            }

        return calc(t) === d1 && calc(t + 1) === d2
    }

    render() {
        const {
            props,
            handleBlur
        } = this,
        { 
            forwardedRef 
        } = props;

        return (
            <BaseInput
                {...props}
                type="text"
                mask={CNPJ_MASK}
                onBlur={handleBlur}
                ref={forwardedRef}
            />
        );
    }
}

CnpjInput.defaultProps = {
    label: 'CNPJ',
    feedback: {
        invalid: 'Informe um CNPJ válido'
    }
}

export default React.forwardRef<BaseInput, Partial<IBaseInputProps>>((props, ref) => {
    return <CnpjInput {...props} forwardedRef={ref} />
});