import React from 'react';
import BaseInput, { IBaseInputProps } from '../input';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export const REGEX_VALIDATE_URL = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export interface IUrlProps extends IBaseInputProps {
    forwardedRef: any
}

class UrlInput extends React.Component<IUrlProps> {
    static defaultProps: Partial<IUrlProps>;
    
    state = {
        isValid: false
    }

    initializeComponent = (value: any) => {
        let isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        this.setState({ isValid });

        if (forwardedRef) {
            let instance = forwardedRef.current;

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else
                instance.setIsInvalid(false);
        }
    }

    onBlur = (event: any) => {
        let value = event.target.value;
        this.initializeComponent(value);
    }

    checkIfIsValid = (value: any) => {
        let url = String(value).toLowerCase();
        return REGEX_VALIDATE_URL.test(url);
    }

    handleUrl = () => {
        let forwardedRef = this.props.forwardedRef;
        if (forwardedRef) window.open(forwardedRef.current.state.value);
    }

    render() {
        const { 
            props,
            state,
            onBlur,
            handleUrl,
            initializeComponent
        } = this,
        {
            forwardedRef, 
        } = props;
        
        let dynamicProps: any = {};

        if (state.isValid) {
            dynamicProps.append = {
                buttonProps: {
                    variant: "secondary"
                },
                icon: faExternalLinkAlt,
                onClick: handleUrl
            }
        }

        return (
            <BaseInput
                {...props}
                type="text"
                transformCase="lower"
                onBlur={onBlur}
                ref={forwardedRef}
                onSetValue={initializeComponent}
                {...dynamicProps}
            />
        );
    }
}

UrlInput.defaultProps = {
    label: 'Url',
    feedback: {
        invalid: 'Informe uma URL válida'
    }
}

export default React.forwardRef<BaseInput, Partial<IBaseInputProps>>((props, ref) => {
    return <UrlInput {...props} forwardedRef={ref} />
});