import * as React from 'react';
import BaseInput, { IBaseInputProps } from '../input';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Util from '../../util';

export const HOME_OR_COMMERCIAL_PHONE_MASK = ['(', /\d/, /\d/, ')', ' ',  /\d/, /\d/, /\d/, /\d/, '-',  /\d/, /\d/, /\d/, /\d/];
export const CELL_PHONE_MASK = ['(', /\d/, /\d/, ')', ' ',  /\d/, /\d/, /\d/, /\d/, /\d/, '-',  /\d/, /\d/, /\d/, /\d/];
export const FREE_PHONE_MASK = [/\d/, /\d/, /\d/, /\d/, ' ',  /\d/, /\d/, /\d/, ' ',  /\d/, /\d/, /\d/, /\d/];

export interface IPhoneInputProps extends IBaseInputProps {
    phoneType?: 'home_phone' | 'cell_phone' | 'free_phone',
    showLinkToWhatsapp?: boolean,
    forwardedRef: any
}

class PhoneInput extends React.Component<IPhoneInputProps> {
    static defaultProps: Partial<IPhoneInputProps>;

    state = {
        phoneType: this.props.phoneType,
        isValid: false
    }

    initializeComponent = (value: any) => {
        let isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        this.setState({ isValid });

        if (forwardedRef) {
            let instance = forwardedRef.current;

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else
                instance.setIsInvalid(false);
        }
    }

    onBlur = (event: any) => {
        let value = event.target.value;
        this.initializeComponent(value);
    }

    checkIfIsValid = (value: any) => {
        let phone = value.replace(/[^\d]+/g, ''),
            blackList = [
            '00000000', '11111111', '22222222', '33333333', '44444444', 
            '55555555', '66666666', '77777777', '88888888', '99999999'
        ];

        if (!value || blackList.some(item => {
            if (this.state.phoneType === 'cell_phone' || this.state.phoneType === 'free_phone')
                return phone.substr(2) === (item.padEnd(9, item.substr(-1)))

            return phone.substr(2) === item
        })) return false;

        if ((this.state.phoneType === 'cell_phone' || this.state.phoneType === 'free_phone') && phone.length !== 11) return false;
        if (this.state.phoneType === 'home_phone' && phone.length !== 10) return false;
        
        return true;
    }

    mask = (value: string) => {
        value = value.replace(/[^\d]+/g, '');
        
        let mask: any = [],
            phoneType = '';

        if (value.indexOf('0') === 0) {
            phoneType = 'free_phone';
            mask = FREE_PHONE_MASK;
        }
        else if (value.indexOf('9') === 2) {
            phoneType = 'cell_phone';
            mask = CELL_PHONE_MASK;
        }
        else {
            phoneType = 'home_phone';
            mask = HOME_OR_COMMERCIAL_PHONE_MASK;
        }

        this.setState({ phoneType });

        return mask;
    }

    dynamicMask = (value: string) => {        
        if (this.props.phoneType === 'cell_phone') return CELL_PHONE_MASK;
        if (this.props.phoneType === 'free_phone') return FREE_PHONE_MASK;
        if (this.props.phoneType === 'home_phone') return HOME_OR_COMMERCIAL_PHONE_MASK;

        return this.mask(value);
    }

    handlePhone = () => {
        let forwardedRef = this.props.forwardedRef;
        if (forwardedRef) Util.openWhatsappWindow(forwardedRef.current.getValue());
    }

    render() {
        const { 
            props, 
            dynamicMask, 
            onBlur,
            handlePhone,
            initializeComponent,
            state
        } = this,
        { 
            forwardedRef,
            showLinkToWhatsapp
        } = props;

        let dynamicProps: any = {};

        if (showLinkToWhatsapp && state.isValid) {
            dynamicProps.append = {
                buttonProps: {
                    variant: "secondary"
                },
                icon: faWhatsapp,
                onClick: handlePhone
            }
        }
        
        return (
            <BaseInput
                {...props}
                mask={dynamicMask}
                onBlur={onBlur}
                onSetValue={initializeComponent}
                ref={forwardedRef}
                {...dynamicProps}
            />
        );
    }
}

PhoneInput.defaultProps = {
    label: 'Telefone',
    feedback: {
        invalid: 'Informe um TELEFONE válido'
    }
}

export default React.forwardRef<BaseInput, Omit<IPhoneInputProps, 'forwardedRef'>>((props, ref) => {
    return <PhoneInput {...props} forwardedRef={ref} />
});