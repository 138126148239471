import React from 'react';
import BaseInput, { IBaseInputProps } from '../input';
import emailMask from 'text-mask-addons/dist/emailMask';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Util from '../../util';

export const EMAIL_MASK = emailMask;
export const REGEX_VALIDATE_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface IEmailProps extends IBaseInputProps {
    forwardedRef: any,
    multiple?: boolean,
    delimiter?: string
}

class EmailInput extends React.Component<IEmailProps> {
    static defaultProps: Partial<IEmailProps>;

    state = {
        isValid: false
    }

    initializeComponent = (value: any) => {
        let isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        this.setState({ isValid });

        if (forwardedRef) {
            let instance = forwardedRef.current;

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else
                instance.setIsInvalid(false);
        }
    }

    onReset = () => {
        this.setState({ isValid: false });
    }

    onBlur = (event: any) => {
        let value = event.target.value;
        this.initializeComponent(value);
    }

    checkIfIsValid = (value: any) => {
        let email: any = String(value).toLowerCase();

        if (this.props.multiple) {
            let emails = email.split(this.props.delimiter!);
            return emails.every((value: string) => REGEX_VALIDATE_EMAIL.test(value));
        }
        else return REGEX_VALIDATE_EMAIL.test(email);
    }

    handleEmail = () => {
        let forwardedRef = this.props.forwardedRef;
        if (forwardedRef) Util.openMailTo(forwardedRef.current.state.value);
    }

    render() {
        const { 
            props, 
            onBlur,
            state,
            handleEmail,
            initializeComponent,
            onReset
        } = this,
        {
            forwardedRef,
            multiple,
            delimiter
        } = props;

        let dynamicProps: any = {};
        
        if (multiple) 
            dynamicProps.informationMessage = `Você pode informar mais de um e-mail 
        separando-os sem espaço, pelo delimitador "${delimiter}"`;
        else dynamicProps.mask = EMAIL_MASK;

        if (state.isValid)
            dynamicProps.append = {
                buttonProps: {
                    variant: "secondary"
                },
                icon: faEnvelope,
                onClick: handleEmail
            }

        return (
            <BaseInput
                {...props}
                onBlur={onBlur}
                isEmail
                transformCase="lower"
                ref={forwardedRef}
                onSetValue={initializeComponent}
                onReset={onReset}
                {...dynamicProps}
            />
        );
    }
}

EmailInput.defaultProps = {    
    label: 'Email',
    feedback: {
        invalid: 'Informe um EMAIL válido'
    },
    delimiter: ';'
}

export default React.forwardRef<BaseInput, Omit<IEmailProps, 'forwardedRef'>>((props, ref) => {
    return <EmailInput {...props} forwardedRef={ref} />
});