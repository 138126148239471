import React from "react";
import { Form } from "react-bootstrap";
import { 
    REQUIRED_MESSAGE, 
    RequiredIcon 
} from "../base";
import { 
    IBaseCheckboxRadioSwitchProps, 
    IBaseCheckboxRadioSwitchState 
} from "./types";

class BaseCheckboxRadioSwitch extends React.Component<IBaseCheckboxRadioSwitchProps, IBaseCheckboxRadioSwitchState> {
    state: IBaseCheckboxRadioSwitchState = {
        value: false,
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    componentDidMount() {
        if (this.props.checked)
            this.setValue(this.props.checked);
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.checked;

        this.setState({ 
            isInvalid: this.props.required && !value ? true : false,
            value 
        });

        if(this.props.onChange) this.props.onChange(event);
    }

    reset = () => this.setState(
        { 
            value: this.props.checked || false,
            isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
            isValid: this.props.isValid ? this.props.isValid : false,
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            hidden: this.props.hidden ? this.props.hidden : false
        }
    );

    setValue = (value: boolean, rawValue: any = null) => this.setState({ value });
    getValue = () => this.state.value;
    getRawValue = () => this.state.value;
    
    getDisplayValue = () => {
        const { translateValue } = this.props;

        if (translateValue)
            return this.state.value ? translateValue.checked : translateValue.unchecked;
        
        return this.state.value.toString();
    }

    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });

    checkIfHasInvalidFeedback = () => {
        const { props, state} = this;

        if (!state.isInvalid) return null;

        let feedback = '';
        if (props.required && !state.value)
            feedback = REQUIRED_MESSAGE;
        else if (props.feedback && props.feedback.invalid)
            feedback = props.feedback.invalid;
        else 
            return null;
       
        return feedback;
    }

    render() {
        const { 
            props, 
            state, 
            onChange, 
            checkIfHasInvalidFeedback 
        } = this,
        label = props.required ? 
            <>
                {props.label} 
                <RequiredIcon /> 
            </> : 
            props.label;

        return (
            <Form.Group hidden={props.hidden}>
                <Form.Check
                    {...props}
                    custom
                    id={`${props.id}-checkbox-radio-switch-${props.name}`}
                    checked={state.value}
                    readOnly={state.readOnly}
                    disabled={state.disabled}
                    isValid={state.isValid}
                    isInvalid={state.isInvalid}

                    label={label}
                    onChange={onChange}
                    feedback={checkIfHasInvalidFeedback()}
                />
            </Form.Group>
        )
    }
}

export default BaseCheckboxRadioSwitch;