import BaseService from './base-service'

const baseEndpoint: string = window.logService.base;
const baseService = new BaseService();
baseService.setBaseEndpoint(baseEndpoint);

interface ILogServiceParams {
    id: number,
    keys: string,
    moduleId?: number,
    page: number,
    start: number,
    limit: number,
    sorter: string
}

class LogService {
    static setBaseEndpoint = (endpoint: string) => {
        baseService.setBaseEndpoint(endpoint);
    }
    
    static get = (params: ILogServiceParams) => {
        const { id, keys, moduleId, page, start, limit, sorter} = params;
        return baseService.get(`${window.logService.log}?idLog=${id}&dsChave=${keys}&idModulo=${moduleId}&page=${page}&start=${start}&limit=${limit}&sort=${sorter}`);
    }
}

export default LogService;