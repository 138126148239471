import React from 'react';
import DateInput, { IDateInputProps } from '../date';

export interface IDateRangeProps {
    startDate: IDateInputProps,
    endDate: IDateInputProps
}

export default class DateRangeInput extends React.Component<IDateRangeProps> {
    static defaultProps: IDateRangeProps;

    state = {
        startDate: null,
        endDate: null
    }
 
    setStartDate = (startDate: Date | null) => {
        this.setState(() => ({
            startDate
        }));
    }

    setEndDate = (endDate: Date | null) => {
        this.setState(() => ({
            endDate
        }));
    }

    render() {
        const { startDate, endDate } = this.props;
        const { startDate: startDateState, endDate: endDateState} = this.state;

        return (
            <>
                <DateInput
                    {...startDate}
                    selected={startDateState} 
                    startDate={startDateState} 
                    endDate={endDateState}
                    maxDate={endDateState}
                    selectsStart 
                    callbackChange={(date) => this.setStartDate(date)}
                    ref={startDate.forwardedRef}
                />
                <DateInput
                    {...endDate}
                    selected={startDateState} 
                    startDate={startDateState} 
                    endDate={endDateState} 
                    minDate={startDateState}
                    selectsEnd 
                    callbackChange={(date) => this.setEndDate(date)}
                    ref={endDate.forwardedRef}
                />
            </>
        );
    }
}

DateRangeInput.defaultProps = {
    startDate: {
        label: "Data início",
        name: "startDate",
        id: "startdate-datepicker"
    },
    endDate: {
        label: "Data fim",
        name: "endDate",
        id: "enddate-datepicker"
    }
}