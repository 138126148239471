import React from 'react';

/**
 * Propriedades do contexto do usuário que está logado nos componentes
 */
export interface IContextPermissionProps {
    /** id do usuário (MOVE) */
    userId: number,
    /** id do módulo da tela configurado */
    moduleId: number,
    /** token gerado na autenticação */
    token: string,
    /** lista de permissões */
    permissionIdList: Array<string>
    checkIfHasPermission(permissionId: string, permissionIdList: Array<string>): boolean | undefined
    setFilter?(): void
}

/**
 * Instância do contexto (inicialização)
 */
const UserContext = React.createContext<IContextPermissionProps>({
    moduleId: 0,
    userId: 0,
    token: '',
    permissionIdList: [],
    checkIfHasPermission: () => undefined,
    setFilter: () => undefined
})

/**
 * Instância do contexto para fornecer os dados
 */
export const UserProvider = UserContext.Provider

/**
 * Instância do contexto para consumir os dados
 */
export const UserConsumer = UserContext.Consumer

/**
 * Instância geral do contexto para fornecer e consumir os dados
 */
export default UserContext