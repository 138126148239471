import React from 'react';
import BaseInput, { IBaseInputProps } from '../input';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Util from '../../util';

export const DEFAULT_CURRENCY_MASK: CurrencyMaskOptions = {
    prefix: 'R$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 11,
    allowNegative: true,
    requireDecimal: false,
    allowLeadingZeroes: false
};

interface CurrencyMaskOptions {
    prefix?: string,
    suffix?: string,
    includeThousandsSeparator?: boolean,
    thousandsSeparatorSymbol?: string,
    allowDecimal?: boolean,
    decimalSymbol?: string,
    decimalLimit?: number,
    integerLimit?: number,
    requireDecimal?: boolean,
    allowNegative?: boolean,
    allowLeadingZeroes?: boolean
}

interface ICurrencyProps extends IBaseInputProps {
    forwardedRef: any,
    maskOptions: CurrencyMaskOptions
}

class CurrencyInput extends React.Component<ICurrencyProps> {
    static defaultProps: Partial<ICurrencyProps>;

    private currencyMaskMerged = {
        ...DEFAULT_CURRENCY_MASK,
        ...this.props.maskOptions
    }

    private currencyMask = createNumberMask(this.currencyMaskMerged);
    
    onBlur = (event: any) => {
        let value = event.target.value,
            isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {
            let instance = forwardedRef.current;

            instance.setValue(value);

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && !isValid)
                instance.setIsInvalid(true);
            else if (!value)
                instance.setIsValid(false);
            else
                instance.setIsInvalid(false);
        }
    }

    checkIfIsValid = (value: any) => {
        let decimalRegexp = new RegExp(`,\\d{${this.currencyMaskMerged.decimalLimit}}$`);

        if (this.currencyMaskMerged.requireDecimal) 
            return decimalRegexp.test(value);

        value = Util.getDecimalNumber(value);

        if (this.props.min && value < this.props.min ||
            this.props.max && value > this.props.max)
            return false

        return true;
    }

    render() {
        const { 
            props, 
            onBlur, 
            currencyMask 
        } = this,
        {
            forwardedRef, 
        } = props;

        return (
            <BaseInput
                {...props}
                mask={currencyMask}
                onBlur={onBlur}
                isCurrency
                ref={forwardedRef}
            />
        );
    }
}

CurrencyInput.defaultProps = {    
    label: 'Valor',
    feedback: {
        invalid: 'Informe um VALOR válido'
    }
}

export default React.forwardRef<BaseInput, Omit<Partial<ICurrencyProps>, 'forwardedRef'>>((props, ref) => {
    return <CurrencyInput {...props} forwardedRef={ref} />
});