import React, { RefObject } from "react";
import BaseInput from "../../input/input";
import { Form, Col } from "react-bootstrap";
import BaseFieldSet from "../../fieldset";
import BaseDropdown from "../../input/dropdown";

export interface IBankInputGroupProps {
    id: {
        name: string,
        innerRef: RefObject<any>
    },
    bank: {
        name: string,
        innerRef: RefObject<any>
    },
    agency: {
        name: string,
        innerRef: RefObject<any>
    },
    account: {
        name: string,
        innerRef: RefObject<any>
    }
}

class BankInputGroup extends React.Component<IBankInputGroupProps> {
    render() {
        return (
            <BaseFieldSet legend="Banco">
                <Form.Row>
                    <Col xs="12" sm="12" md="3" lg="2" xl="2">
                        <BaseInput
                            {...this.props.id}
                            label="Id"
                            readOnly
                            identifier
                            ref={this.props.id.innerRef}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="9" lg="10" xl="10">
                        <BaseDropdown 
                            {...this.props.bank}
                            type="number"
                            label="Banco"
                            endpoint={`${window.commonService.base}${window.commonService.bank}`}
                            labelProperty="name"
                            required
                            ref={this.props.bank.innerRef}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="5" lg="5" xl="5">
                        <BaseInput
                            {...this.props.agency}
                            label="Agência"
                            required
                            maxLength={15}
                            ref={this.props.agency.innerRef}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="7" lg="7" xl="7">
                        <BaseInput 
                            {...this.props.account}
                            label="Conta"
                            required
                            maxLength={15}
                            ref={this.props.account.innerRef}
                        />
                    </Col>
                </Form.Row>
            </BaseFieldSet>
        );
    }
}

export default BankInputGroup;