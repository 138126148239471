import React from "react"
import BaseSelect from '../select';
import { 
    IBaseSelectProps, 
    ISelectOptions 
} from '../select/types'

interface IStateInputProps extends IBaseSelectProps {
    forwardedRef: React.Ref<any>
}

class StateInput extends React.Component<IStateInputProps> {
    static defaultProps: Partial<IStateInputProps>;

    private options: ISelectOptions[] = [
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapá" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espírito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piauí" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SC", label: "Santa Catarina" },
        { value: "SP", label: "São Paulo" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" }
    ];

    render() {
        const {
            props,
            options
        } = this,
        {
            forwardedRef 
        } = props;

        return (
            <BaseSelect
                {...props}
                type="string"
                options={options}
                ref={forwardedRef}
            />
        );
    }
}

StateInput.defaultProps = {
    label: 'Estado'
}

export default React.forwardRef<BaseSelect, Partial<IBaseSelectProps>>((props, ref) => {
    return <StateInput {...props} forwardedRef={ref} />
});