import React from 'react'
import { Row, Col, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

type BaseNavBarProps = {
    name: string,
    data: Array<any>,
    callbackOnSelected?(selectedNavigation: number): void
}

class BaseNavBar extends React.Component<BaseNavBarProps, { selectedNavigation: number }> {
    state = {
        selectedNavigation: 1
    }

    selectNavigation = (mode: "left" | "right") => {
        const { callbackOnSelected } = this.props;
        const { selectedNavigation } = this.state;

        let selected = mode === "right" ? selectedNavigation + 1 : selectedNavigation - 1;

        this.setState({
            selectedNavigation: selected
        }, () => {
            if (callbackOnSelected) callbackOnSelected(selected);
        });
    }

    render() {
        const { selectedNavigation } = this.state;
        const { name, data } = this.props;

        return <div className="mb-2 pb-2 border-bottom">
            <Row>
                <Col className="text-left" xs="2" sm="2" lg="2" md="2" xl="2">
                    <Button id={`${name}-form-navbar-btn-prev`} onClick={() => this.selectNavigation("left")} type="button" size="sm" disabled={data.length === 1 || selectedNavigation === 1}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </Col>
                <Col className="text-center" xs="8" sm="8" lg="8" md="8" xl="8">
                    Exibindo <Badge variant="secondary">{selectedNavigation}</Badge> de <Badge variant="secondary">{data.length}</Badge>
                </Col>
                <Col className="text-right" xs="2" sm="2" lg="2" md="2" xl="2">
                    <Button id={`${name}-form-navbar-btn-next`} onClick={() => this.selectNavigation("right")} type="button" size="sm" disabled={data.length === 1 || selectedNavigation === data.length}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                </Col>
            </Row>
        </div>;
    }
}

export default BaseNavBar;