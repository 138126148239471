import React from "react";
import BaseInput, { IBaseInputProps } from '../input';

export const CPF_MASK = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

interface ICpfInputProps extends IBaseInputProps {
    forwardedRef: any
}

class CpfInput extends React.Component<ICpfInputProps> {
    static defaultProps: Partial<IBaseInputProps>;

    onBlur = (event: any) => {
        let value = event.target.value,
            isValid = this.checkIfIsValid(value),
            forwardedRef = this.props.forwardedRef;

        if (forwardedRef) {
            let instance = forwardedRef.current;
            
            instance.setValue(value.replace(/[^\d]+/g, ''));

            if (value && isValid)
                instance.setIsInvalid(false);
            else if (value && isValid === false)
                instance.setIsInvalid(true);
            else if (!value)
                instance.setIsValid(false);
            else
                instance.setIsInvalid(false);
        }
    }

    checkIfIsValid = (value: any) => {
        let cpf = value.replace(/[^\d]+/g,''),
            cpfBlackList = [
                "00000000000", "11111111111", "22222222222", "33333333333", "44444444444", 
                "55555555555", "66666666666", "77777777777", "88888888888", "99999999999"
            ];
        
        if (!cpf || 
            cpf.length !== 11 ||
            cpfBlackList.some((value) => value === cpf)) return false;

        let sum = 0,
            rest = 0;

        for (let i = 1; i <= 9; i++) 
            sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);

        rest = (sum * 10) % 11;
        if ((rest === 10) || (rest === 11)) 
            rest = 0;

        if (rest !== parseInt(cpf.substring(9, 10)) ) 
            return false
        
        sum = 0;
        for (var i = 1; i <= 10; i++) 
            sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
    
        rest = (sum * 10) % 11;
        if ((rest === 10) || (rest === 11))  
            rest = 0;

        if (rest !== parseInt(cpf.substring(10, 11))) 
            return false;
        
        return true;
    }

    render() {
        const {
            props,
            onBlur
        } = this;
        const { 
            forwardedRef 
        } = props;

        return (
            <BaseInput
                {...props}
                type="text"
                mask={CPF_MASK}
                onBlur={onBlur}
                ref={forwardedRef}
            />
        );
    }
}

CpfInput.defaultProps = {
    label: 'CPF',
    feedback: {
        invalid: 'Informe um CPF válido'
    }
}

export default React.forwardRef<BaseInput, Partial<IBaseInputProps>>((props, ref) => {
    return <CpfInput {...props} forwardedRef={ref} />
});