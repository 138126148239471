import React, { ReactElement } from 'react';
import Axios from 'axios';
import { Table, Pagination, Row, Col, Badge, Form, OverlayTrigger, Tooltip, Button, Dropdown, ListGroup, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faSort, faSortDown, faSortUp, faSyncAlt, faCheckDouble, faCheck, faColumns, faInfoCircle, faTimes, faFilter, faRecycle, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import Loading from '../loading';
import Util from '../util';
import UserContext from '../context/user';
import CopyToClipboard from 'react-copy-to-clipboard';
import './style.scss';

export interface IPagination
{
    page: number,
    limit: number,
    pages: number,
    records: number,
    first: number,
    last: number
}

export interface ISorter
{
    propertyName: string,
    direction: SorterType
}

export enum FilterOperator
{
    GreatThan = ">",
    LessThan = "<",
    Equals = "=",
    GreatOrEqualsThan = ">=",
    LessOrEqualsThan = "<=",
    Contains = "like"
}

export interface IFilter
{
    property: string,
    operator: FilterOperator,
    value: any,
    display?: string,
    label?: string,
    mask?: Array<any> | ((value: string) => Array<any>) | undefined,
    closeable?: boolean,
    hidden?: boolean
}

export interface IServerSidePagination
{
    page: number,
    itemsPerPage: number,
    sorter?: ISorter[] | undefined,
    filter?: IFilter[] | undefined
}

export interface ITableColumn
{
    name: string,
    displayName: string,
    sortable?: boolean,
    identifier?: boolean,
    width?: string,
    sorter?: SorterType,
    mask?: Array<any>,
    type?: "date" | "time" | "datetime",
    hidden?: boolean,
    permissionId?: string,
    onRender?(value: any, record: any): any  
}

enum SorterType
{
    asc = "asc",
    desc = "desc"
}

export interface IBaseGridProps
{
    baseEndpoint?: string, 
    toolbar?: object,
    mode: {
        type: "automatic" | "callback",
        automatic?: {
            endpoint: string
        },
        callback?: {
            data: any[],
            pagination: IPagination,
            handleServerSide? (iServerSidePagination: IServerSidePagination) : Promise<void>
        }
    },
    load: boolean,
    columns: ITableColumn[],
    filter: IFilter[],
    highlightRows?: {
        conditions: Array<
            {
                name: string,
                value: any
            }
        >,
        className: string
    },
    onSelect?(data: any[]): void,
    name: string,
    dependentProperty?: {
        name: string,
        value: any
    }
}

interface IBaseGridState
{
    error?: {
        code: number,
        message: string
    },
    data: any[],
    pagination: IPagination,
    selected: {
        all: boolean,
        items: any[]
    },
    columns: ITableColumn[],
    hideLoading: boolean,
    pageList: number[],
    filter: IFilter[],
    loaded: boolean,
    showClipboardMessage: boolean,
    search: boolean
}

export default class BaseGrid extends React.Component<IBaseGridProps, IBaseGridState>
{
    context!: React.ContextType<typeof UserContext>;
    static defaultProps: IBaseGridProps;

    state: IBaseGridState = {
        data: [],
            pagination: {
                page: 1,
                limit: 10,
                pages: 1,
                records: 0,
                first: 1,
                last: 1
            },
            selected:  {
                all: false,
                items: []
            },
            columns: this.props.columns,
            hideLoading: true,
            pageList: [1],
            filter: this.props.filter,
            loaded: false,
            showClipboardMessage: false,
            search: false
    }

    componentDidMount()
    {
        if (this.props.load)
            this.loadPage();
    }

    componentDidUpdate(prevProps: IBaseGridProps, prevState: IBaseGridState)
    {
        if (!this.state.search && (prevProps.load || this.props.load) && JSON.stringify(this.props.filter) !== JSON.stringify(this.state.filter))
            this.setFilter([], false);
    }

    setFilter = (filter: IFilter[], search: boolean = true) =>
    {
        this.setState({
            filter: this.props.filter ? [ ...this.props.filter, ...filter ] : filter,
            search: filter.length === 0 ? false : search,
            selected: {
                all: false,
                items: []
            }
        }, () =>
        {
            this.loadPage();
            this.props.onSelect && this.props.onSelect([])
        });
    }

    removeFilterByName = (filterName: string) =>
    {
        let newFilter = this.state.filter.filter(value =>
        {
            return value.property !== filterName;
        });

        this.setState({
            filter: newFilter,
            search: newFilter.length === 0 ? false : true
        }, this.loadPage);
    }

    removeAllFilter = () =>
    {
        this.setState({
            filter: this.state.filter.filter(value => value.closeable === false),
            search: false
        }, () => {
            this.loadPage();
        });
    }

    private getSorter = () =>
    {
        const { columns } = this.state;

        const sorter = columns.filter(value =>
        {
            return value.sorter !== undefined;
        }).map(value =>
        {
            const { name, sorter } = value;

            return {
                propertyName: name,
                direction: sorter
            } as ISorter;
        });

        return sorter;
    }

    private getGridParams = (page: number) =>
    {
        const { filter } = this.state;

        const gridParams: IServerSidePagination = {
            page,
            itemsPerPage: this.state.pagination.limit,
            filter: filter.map(value => ({ operator: value.operator, property: value.property, value: value.mask ? value.value.replace(/[^\d]+/g, '') : value.value })),
            sorter: this.getSorter()
        };

        return gridParams;
    }
    
    private endpointFormat = (page: number) : string =>
    {
        const gridParams = this.getGridParams(page);
        return `${this.props.baseEndpoint}${this.props.mode.automatic!.endpoint}?gridParams=${JSON.stringify(gridParams)}&userId=${this.context.userId}`;
    }

    private handleServerSideCallback = async (page: number) =>
    {
        if (this.props.mode.callback && this.props.mode.callback.handleServerSide)
        {
            const sorter = this.state.columns.filter((value, i) =>
            {
                return value.sorter !== undefined;
            }).map((value, i) =>
            {
                const { name, sorter } = value;

                return {
                    propertyName: name,
                    direction: sorter
                } as ISorter;
            });

            await this.props.mode.callback.handleServerSide({ 
                page, 
                itemsPerPage: this.state.pagination.limit,
                sorter
            });
        }

        const { pages, records } = this.props.mode.callback!.pagination;

        this.setState(() => ({
            data: this.props.mode.callback!.data,
            pagination: {
                ...this.state.pagination, 
                pages,
                records
            }
        }), this.pages);
    }

    private handleServerSideAutomatic = async (page: number) =>
    {
        let result = await Axios.get(this.endpointFormat(page));
            
        const { success, data, pagination, error } = result.data;

        if (success)
        {
            this.setState(() => ({
                error,
                data,
                pagination: {
                    ...this.state.pagination, 
                    pages: pagination.totalPages || 1,
                    records: pagination.totalItems
                }
            }), this.pages);
        }
        else
        {
            this.setState(() => ({
                error,
                data: [],
                pagination: {
                    ...this.state.pagination, 
                    pages: 1,
                    records: 0
                },
                selected:  {
                all: false,
                items: []
            }
            }), () =>
            {
                this.pages();
                this.props.onSelect && this.props.onSelect([])
            });
        }
    }

    private loadPage = async (page: number = 1) =>
    {
        this.setState({
            hideLoading: false,
            loaded: true,
            pagination: {
                ...this.state.pagination, 
                page
            }
        });

        if (this.props.mode.type === "callback")
            await this.handleServerSideCallback(page);
        else
            await this.handleServerSideAutomatic(page);            
    }

    private checkShowSelectAllColumn = (columns:  ITableColumn[]) =>
    {
        if (columns[0].name !== "selectItem")
            columns.splice(0, 0, {
                name: "selectItem",
                displayName: "Selecionar",
                sortable: false
            });
    }

    private sorter = (value: ITableColumn) =>
    {
        const { columns } = this.state;
        const { asc, desc} = SorterType;

        columns.filter(column =>
        {
            return column.name === value.name && 
            (column.sorter = column.sorter === asc ? desc : asc);
        });

        this.setState({
            columns: columns.length === 0 ? [...this.state.columns, { ...value, sorter: asc}] : columns
        }, this.loadPage);
    }

    private columnHidden = (value: ITableColumn) =>
    {
        const { columns } = this.state;
        
        columns.filter(column =>
        {
            return column.name === value.name && 
            (column.hidden = !column.hidden);
        });

        this.setState({
            columns: columns.length === 0 ? [...this.state.columns, { ...value, hidden: true}] : columns
        });
    }

    private currentSorter = (column: ITableColumn) =>
    {
        let sorterType = this.sorterType(column);

        if (sorterType === SorterType.asc.valueOf())
            return faSortUp;
        else if (sorterType === SorterType.desc.valueOf())
            return faSortDown;
        else
            return faSort;
    }

    private sorterType = (column: ITableColumn) =>
        column.sorter ? column.sorter : null;

    private setItemsPerPage = (item: number) =>
    {
        this.setState({
            pagination: {
                ...this.state.pagination, limit: item 
            },
            selected: {
                ...this.state.selected,
                items: []
            }
        }, () =>
        {
            this.loadPage(1);
            this.props.onSelect && this.props.onSelect([])
        });
    }

    private nextPage = () =>
    {
        const { page, pages } = this.state.pagination;

        if (page === pages) 
            return;
        
        this.loadPage(page + 1);
    }

    private previousPage = () =>
    {
        let selectedPage = this.state.pagination.page;
        
        if (selectedPage === 1) 
            return;
        
        this.loadPage(selectedPage - 1);
    }

    private pages = () =>
    {
        const { pages, page, first, last } = this.state.pagination
        let pageList: any = [];

        if (pages <= 9)
        {
            pageList = [];
            for (let i = 0; i < pages; i++) pageList.push(i + 1);

            this.setState({
                pageList,
                hideLoading: true
            });

            return;
        }
      
        if (page > first && page < last)
        {
            this.setState({ hideLoading: true });
            return;
        };
      
        pageList = [];
        for (let i = 0; i < (page > 6 && page + 5 < pages ? 5 : 7); i++)
        {
            if (page > 1 && page - i >= 1 && page < first)
                pageList.splice(0, 0, page - i);
            else if (page > 1 && page - i + 1 >= 1 && page === first)
                pageList.splice(0, 0, page - i + 1);
            else if (page > 1 && page + i - 1 > pages)
                pageList.splice(0, 0, pageList[0] - 1);
            else if (page > 1 && page - i - 1 < 1)
                pageList.push(pageList[pageList.length - 1] + 1);
            else if (page > 1 && page === last) 
                pageList.push(page - 1 + i);
            else if (page > 1 && page + i > pages) 
                pageList.splice(0, 0, pageList[0] - 1);
            else
                pageList.push(page + i);
        }        
      
        let firstP = pageList[0],
            lastP = pageList[pageList.length - 1];
    
        if (page > 6 && firstP > 1)
            pageList.splice(0, 0, 1, -1);
        
        if (pages > 6 && page < pages && lastP < pages)
            pageList.push(-2, pages);

        this.setState({
            pagination: {
                ...this.state.pagination,
                first: firstP,
                last: lastP
            },
            pageList,
            hideLoading: true
        });
    }

    private recordsFrom = () =>
    {
        const { limit, records } = this.state.pagination;
        let totalRecords = this.totalRecords() - limit + ( records > 0 ? 1 : 0);

        return totalRecords;
    }

    private recordsTo = () =>
    {
        const { records } = this.state.pagination;
        let totalRecords = this.totalRecords();

        return totalRecords > records ? records : totalRecords;
    }

    private getIdentifiers = () =>
    {
        let identifiers = this.state.columns.filter(value => value.identifier).map(value => value.name);
        
        if (identifiers.length > 0)
            return identifiers;
        else
            return ['id'];
    }

    private getIdentifier = (item: any) =>
    {
        let identifiers = this.getIdentifiers(),
            itemId = '';

        identifiers.forEach(id =>
        {
            if (item[id]) itemId += item[id];
        });

        return itemId;
    }

    private selectItem = (item: any) =>
    {
        const { selected } = this.state;

        let itemId = this.getIdentifier(item);

        if (selected.items.some(value => this.getIdentifier(value) === itemId))
            selected.items = selected.items.filter(value => this.getIdentifier(value) !== itemId);
        else
            selected.items = [...selected.items, item];

        this.setState({ selected },
                      () => this.props.onSelect && this.props.onSelect(selected.items));
    }

    private selectAllPageItems = () =>
    {    
        const { selected, data } =  this.state;  

        if (this.checkSelectedAllItems())
        {
            data.forEach(data => this.selectItem(data))
        }
        else
        {
            data.filter(data => !selected.items.some(item => this.getIdentifier(item) === this.getIdentifier(data)))
                .forEach(data => this.selectItem(data))
        }
    }
    
    private selectAllItems = () =>
    {
        const { selected, data } =  this.state;

        this.setState({
            selected: {
                all: this.state.selected.items.length > 0 ? false : !this.state.selected.all,
                items: this.state.selected.items.length > 0
                            ? []
                            : data.filter(data => !selected.items.some(item => this.getIdentifier(item) === this.getIdentifier(data)))
            }
        }, () => this.props.onSelect && this.props.onSelect([]));    
    }

    private checkSeletedItem = (item: any) =>
    {
        let itemId = this.getIdentifier(item);
        return this.state.selected.items.some(value => this.getIdentifier(value) === itemId) || this.state.selected.all;
    }

    private checkSelectedAllItems = () =>
    {
        if (this.state.data.length === 0)
            return false;
        
        if (this.state.selected.all)
            return true;

        return this.state.data.every(value => this.state.selected.items.some(item => this.getIdentifier(item) === this.getIdentifier(value)));
    }

    private removeSeletedItems = () =>
    {
        this.setState({
            selected: {
                all: false,
                items: []
            }
        }, () => this.props.onSelect && this.props.onSelect([]));    
    }

    private checkHighlightRow = (item: any) : string =>
    {
        const { highlightRows } = this.props;

        if (highlightRows)
        {
            let hasHighlightRows = highlightRows.conditions.filter((value) =>
            {
                return item[value.name] && value.value === item[value.name];
            });

            if (hasHighlightRows.length > 0)
                return highlightRows.className;
        }

        return '';
    }

    private totalRecords()
    {
        const { page, limit } = this.state.pagination;
        return page * limit;
    }

    private addTableColumn = (tableColumn: ITableColumn, columnI: number, item: any, itemI: number) =>
    {
        if (columnI === 0) 
            return <Form.Check 
                        disabled={this.state.selected.all} 
                        custom 
                        label="" 
                        onChange={() => this.selectItem(item)} 
                        checked={this.checkSeletedItem(item) ? true : false} 
                        type="checkbox" 
                        id={this.props.name + "-selectItem-" + itemI.toString()} 
                    />;
        else
        {
            let value = Util.getObjectValueFromObject(item, tableColumn.name);

            if (value === null)
            {
                console.warn("Model com valor null: " + tableColumn.name);
                return;
            }

            if (tableColumn.onRender)
                value = tableColumn.onRender(value, item);

            if (typeof value === 'boolean')
                value = value.toString();
    
            if (tableColumn.mask)
                return Util.conformToMask(value, tableColumn.mask);
            else if (tableColumn.type === "date")
                return Util.dateISOToDate(value);
            else if (tableColumn.type === "time")
                return Util.dateISOToTime(value);
            else if (tableColumn.type === "datetime")
                return Util.dateISOToDateTime(value);
            else 
                return value;
        }
    }

    private addTableHeadColumn = (tableColumn: ITableColumn, i: number) =>
    {
        let sorterButton = <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={tableColumn.name+"-sorter"}>
                                        Ordenar <strong>{!this.sorterType(tableColumn) || this.sorterType(tableColumn) === SorterType.desc ? "A-Z": "Z-A"}</strong>
                                    </Tooltip>
                                }
                            >
                                <Button onClick={() => this.sorter(tableColumn)} size="sm" variant={!this.sorterType(tableColumn) ? 'light' : 'secondary'}>
                                    <FontAwesomeIcon icon={this.currentSorter(tableColumn)} />
                                </Button>
                            </OverlayTrigger>;
        if (i === 0)
            return <Form.Check 
                disabled={this.state.selected.all} 
                custom 
                id={this.props.name +"-checkAll-" + i.toString() + "-" + this.state.pagination.page} 
                onChange={this.selectAllPageItems} 
                checked={this.checkSelectedAllItems()} 
                type="checkbox" 
                label="" 
            />
        else
        {
            if (tableColumn.sortable === undefined || tableColumn.sortable)
            {
                return (
                    <>
                        {!tableColumn.hidden && tableColumn.displayName}
                        <span className="ml-1">
                            {!tableColumn.hidden && sorterButton}      
                        </span>     
                    </>
                );
            }
            else    
                return !tableColumn.hidden && tableColumn.displayName;
        }
    }

    handleSync = () =>
        this.loadPage(this.state.pagination.page);

    handleRecycle = () =>
    {
        this.setState((state) => ({
            filter: this.state.filter.filter(value => value.closeable === false),
            columns: state.columns.map((value) =>
            {
                delete value.sorter;
                return value;
            }),
            pagination: {
                ...state.pagination,
                page: 1,
                limit: 10,
            },
            selected:  {
                all: false,
                items: [],
                pages: []
            },
            hideLoading: true,
            loaded: false
        }), () =>
        {
            this.loadPage();
            this.props.onSelect && this.props.onSelect([]);
        });
    }

    render()
    {
        const { columns, pagination, pageList, data, error, hideLoading, selected, showClipboardMessage } = this.state;  
        const itemsPerPage = [5, 10, 25, 50, 100];
        this.checkShowSelectAllColumn(columns);

        return (
            <div className="border border-top-0 bg-white data-table">
                {
                    this.props.toolbar && React.cloneElement(this.props.toolbar as ReactElement, 
                    { 
                        data: this.state.selected.items, 
                        selectedItems: this.state.selected.items,
                        identifiers: this.getIdentifiers(),
                        name: this.props.name,
                        filter: this.state.filter.filter(item => item.closeable !== false),
                        callbackFilter: this.setFilter,
                        callbackRemoveSelectedItems: this.removeSeletedItems,
                        baseEndpoint: this.props.baseEndpoint,
                        gridParams: this.getGridParams(this.state.pagination.page),
                        dependentProperty: this.props.dependentProperty
                    })
                }

                {
                    this.state.filter.filter(value => value.closeable !== false).length > 1 &&
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`${this.props.name}-tooltip-grid-remove-all-filter`}>
                                    Remover todos
                                </Tooltip>
                            }
                        >
                            <Button variant="danger" id={`${this.props.name}-btn-grid-remove-all-filter`} onClick={this.removeAllFilter} size="sm" className="mb-2 mr-2">
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </OverlayTrigger>
                }
                {
                    this.state.filter.map((value: IFilter, i: number) =>
                        (value.hidden === undefined || !value.hidden) &&
                            <Badge key={i} variant="light" className="mb-2 align-middle mr-2 h-2">
                                <span className="align-middle">
                                    {
                                        value.label ? value.label + ": " : ""
                                    }
                                </span>
                                <span className="align-middle">
                                    {
                                        value.display || (value.mask && Util.conformToMask(value.value, value.mask)) || value.value
                                    }
                                </span>
                                {(value.closeable === undefined || value.closeable) && <Button id={`${this.props.name}-btn-grid-remove-filter-by-name`} onClick={() => this.removeFilterByName(value.property)} variant="link" size="sm" className="p-0 m-0 mx-1">
                                    <FontAwesomeIcon icon={faTimes} />
                                </Button> || <Button disabled variant="link" size="sm" className="p-0 m-0 mx-1">
                                    <FontAwesomeIcon icon={faFilter} />
                                </Button>}
                            </Badge>)
                }
                <Table responsive striped bordered hover size="sm">
                    <thead className="bg-light">
                        <tr>
                            {columns.map((column, i) =>
                                (!column.hidden &&
                                <th className="align-middle" key={i} style={{width: i === 0 || column.hidden ? '1%' : column.width}}>
                                    {this.addTableHeadColumn(column, i)}
                                </th>)
                            )}
                        </tr>
                    </thead>
                    {data.length > 10 && <tfoot>
                        <tr>
                            {columns.map((column, i) =>
                                (!column.hidden &&
                                <th key={i} style={{width: i === 0 || column.hidden ? '1%' : column.width}}>
                                    {i === 0 ? '' : column.displayName}
                                </th>)
                            )}
                        </tr>
                    </tfoot>}
                    <tbody>
                        {data.map((item, itemI) =>
                            <tr 
                                key={itemI} 
                                className={this.checkSeletedItem(item) ? 
                                    'table-primary' : 
                                    this.checkHighlightRow(item) }
                            >
                                {
                                    columns.map((column, columnI) => {
                                        const 
                                            value = this.addTableColumn(column, columnI, item, itemI),
                                            render = (
                                                <td key={columnI}>
                                                    {value}
                                                </td>
                                            );
                                        
                                        if (!column.hidden) {
                                            if (columnI === 0)
                                                return render;
                                            else
                                                return (
                                                    <CopyToClipboard
                                                        key={columnI}
                                                        text={value}
                                                        onCopy={() => this.setState({ showClipboardMessage: true })}
                                                        children={render}
                                                    />
                                                )
                                        }
                                    })
                                }  
                            </tr>
                        )} 
                    </tbody>
                </Table>
                {
                    showClipboardMessage &&
                    <Toast 
                        show={showClipboardMessage} 
                        autohide
                        delay={3000} 
                        onClose={() => this.setState({ showClipboardMessage : false })} 
                        style={{
                            position: 'absolute',
                            top: '-3rem',
                            right: 0
                        }}
                    >
                        <Toast.Header>
                            <FontAwesomeIcon 
                                className="text-success mr-2" 
                                icon={faClipboardCheck}
                                size="lg"
                            />
                            Texto copiado!
                        </Toast.Header>
                    </Toast>
                }
                <Loading hidden={hideLoading} />
                {
                    hideLoading && data.length === 0 && (!error || error && error.code === 404) &&
                        <div className="alert alert-warning mt-2 px-2" role="alert">
                            <FontAwesomeIcon className="text-warning" size="lg" icon={faInfoCircle} /> Nenhum registro encontrado
                        </div>
                }
                {
                    hideLoading && error && error.code === 401 &&
                        <div className="alert alert-danger mt-2 px-2" role="error">
                            <FontAwesomeIcon className="text-danger" size="lg" icon={faInfoCircle} /> {error.message}
                        </div>
                }
                <div className="border-top bg-white" style={{padding: "0.5rem 0 0 0", marginTop: "0.5rem"}}>
                    <Row>
                        <Col className="text-left">
                            <p>Selecionados <Badge variant="secondary">{(selected.all && pagination.records) || (selected.items.length)}</Badge></p>
                        </Col>
                        <Col>
                            <Pagination size="sm" className="justify-content-center custom-pagination">
                                <Pagination.Item id={`${this.props.name}-pagination-previous-page-grid`} onClick={() => this.previousPage()} disabled={pagination.page === 1}><FontAwesomeIcon icon={faArrowLeft} /></Pagination.Item>

                                {pageList.map((item) =>
                                    <Pagination.Item id={`${this.props.name}-pagination-page-grid`} key={item} onClick={() => this.loadPage(item)} active={pagination.page === item} disabled={item === -1 || item === -2} >{item !== -1 && item !== -2 ? item : "..."}</Pagination.Item>  
                                )}

                                <Pagination.Item id={`${this.props.name}-pagination-next-page-grid`} onClick={() => this.nextPage()} disabled={pagination.page === pagination!.pages!} ><FontAwesomeIcon icon={faArrowRight} /></Pagination.Item>
                            </Pagination>
                        </Col>
                        <Col className="text-right">
                            <p>Exibindo <Badge variant="secondary">{this.recordsFrom()}</Badge> a <Badge variant="secondary">{this.recordsTo()}</Badge> de <Badge variant="secondary">{pagination.records}</Badge></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-left">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`${this.props.name}-tooltip-select-all`}>
                                        {this.state.selected.all ? 'Remover selecionar todos' : this.state.selected.items.length > 0 ? 'Remover selecionado(s)' : 'Selecionar todos'}
                                    </Tooltip>
                                }
                            >
                                <Button id={`${this.props.name}-btn-select-all`} size="sm" onClick={this.selectAllItems} className={this.state.selected.all || this.state.selected.items.length > 0 ? 'active rounded-circle' : 'rounded-circle'}>
                                    <FontAwesomeIcon icon={this.state.selected.all || this.state.selected.items.length > 0 ? faCheckDouble : faCheck} />
                                </Button>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`${this.props.name}-tooltip-sync`}>
                                        Recarregar
                                    </Tooltip>
                                }
                            >
                                <Button className="rounded-circle" id={`${this.props.name}-btn-sync`} onClick={this.handleSync} size="sm">
                                    <FontAwesomeIcon icon={faSyncAlt} />
                                </Button>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`${this.props.name}-tooltip-dropdown-toolbar-columns-options-grid`}>
                                        Colunas
                                    </Tooltip>
                                }
                            >
                                <Dropdown drop="right" alignRight className="custom-dropdown">
                                    <Dropdown.Toggle className="rounded-circle" id={`${this.props.name}-dropdown-toolbar-columns-options-grid`} size="sm" >
                                        <FontAwesomeIcon icon={faColumns} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ListGroup variant="flush">
                                            {columns.map((column, i) =>
                                                <ListGroup.Item className="px-2 py-1" key={i}>
                                                    <Form.Check 
                                                        custom
                                                        type="checkbox"
                                                        defaultChecked={!column.hidden}
                                                        onClick={() => this.columnHidden(column)}
                                                        id={`${this.props.name}-dropdown-toolbar-columns-options-grid-${i}`}
                                                        label={column.displayName}
                                                    />
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`${this.props.name}-tooltip-recycle`}>
                                        Resetar
                                    </Tooltip>
                                }
                            >
                                <Button variant="warning" className="rounded-circle" id={`${this.props.name}-btn-recycle`} onClick={this.handleRecycle} size="sm">
                                    <FontAwesomeIcon icon={faRecycle} />
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col className="text-right">
                            <Pagination size="sm" className="justify-content-end custom-pagination">
                                {itemsPerPage.map((item, i) =>
                                    <Pagination.Item key={i} id={`${this.props.name}-pagination-limit-${item}`} onClick={() => this.setItemsPerPage(item)} active={pagination.limit === item} >{item}</Pagination.Item>  
                                )}
                            </Pagination>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }   
}

BaseGrid.defaultProps = {
    mode: {
        type: 'automatic',
        automatic: {
            endpoint: '/Read'
        }        
    },
    load: true,
    columns: [],
    filter: [],
    name: 'default'
};

BaseGrid.contextType = UserContext;