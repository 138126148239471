import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { 
    ICommonInputProps, 
    CustomInputGroupPrepend, 
    CustomInputGroupAppend, 
    CheckHasFeedback, 
    CustomSpinner, 
    CommonLabel, 
    ICommonInputState 
} from "../base";
import MaskedInput from "react-text-mask";
import Util from "../../util";
import { isDate } from "util";
import './style.scss'
import { FilterOperator } from "../../grid";

export interface IBaseInputProps extends ICommonInputProps {
    type?: 'text' | 'currency' | any,
    as?: "input" | "textarea",
    isDate?: boolean,
    isEmail?: boolean,
    isCurrency?: boolean,
    value?: any,
    mask?: Array<any> | ((value: string) => Array<any>) | undefined,
    notAllowSpecialCharacters?: boolean,
    transformCase?: 'upper' | 'lower',
    maxLength?: number,
    autoComplete?: string,
    placeholder?: string,
    identifier?: boolean    
}

interface IBaseInputState extends ICommonInputState {
    value: any,
    rawValue: any,
    loading?: boolean
}

class BaseInput extends React.Component<IBaseInputProps, IBaseInputState> {
    static defaultProps: Partial<IBaseInputProps>;

    state: IBaseInputState = {
        value: '',
        rawValue: '',
        loading: this.props.loading,
        disabled: this.props.disabled,
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
        isValid: this.props.isValid,
        isInvalid: this.props.isInvalid
    }

    static getDerivedStateFromProps(props: IBaseInputProps, state: IBaseInputState) {
        if (props.loading !== state.loading)
            return { loading: props.loading };
        
        return null;
    }

    componentDidMount() {
        if (this.props.value)
            this.setValue(this.props.value);
    }

    onChange = (event: any) => {
        let value = event.target.value;

        if (this.props.notAllowSpecialCharacters) value = value.replace(/[^\w ]+/g, '');

        if (this.props.transformCase) {
            if (this.props.transformCase === 'upper') value = value.toUpperCase();
            else if (this.props.transformCase === 'lower') value = value.toLowerCase();
        }

        this.setState({
            value
        });

        if(this.props.onChange) this.props.onChange(event);
    }

    onBlur = (event: any) => {
        let value = event.target.value;

        if (this.props.required && value) {
            this.setState({
                isInvalid: false
            });
        }

        if (this.props.onBlur) this.props.onBlur(event);
    }

    reset = () => {
        this.setState({
            value: this.props.value || '',
            isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
            isValid: this.props.isValid ? this.props.isValid : false,
            readOnly: this.props.readOnly ? this.props.readOnly : false,
            disabled: this.props.disabled ? this.props.disabled : false,
            hidden: this.props.hidden ? this.props.hidden : false
        }, () => {
            if (this.props.onReset) this.props.onReset();
        });
    }

    setValue = (value: any, rawValue: any = null) => this.setState({ value, rawValue }, () => {
        if (this.props.onSetValue) this.props.onSetValue(value);
    });

    getValue = () => {
        if (this.props.isCurrency) return this.state.value.replace(/[^0-9,]+/g, '').replace(',', '.');        
        return this.state.value;             
    }
    
    getRawValue = () => this.state.rawValue;

    getDisplayValue = () => {
        if (isDate(this.state.value)) return Util.dateISOToDate(this.state.value);

        return this.state.value; 
    }

    setDisabled = (disabled: boolean) => this.setState({ disabled });
    setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
    setHidden = (hidden: boolean) => this.setState({ hidden });
    setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
    setIsValid = (isValid: boolean) => this.setState({ isValid });

    render() {        
        const { props, state, onChange, getValue ,onBlur} = this,
            render = props.mask ?
                <MaskedInput
                    className={`form-control form-control-sm ${state.isValid ? 'is-valid': state.isInvalid ? 'is-invalid' : ''}`}
                    guide={false}

                    readOnly={state.readOnly}
                    disabled={state.disabled}
                    value={state.value}
                    
                    id={props.id}
                    name={props.name}
                    type={props.type}
                    required={props.required}
                    maxLength={props.maxLength}
                    mask={props.mask}
                    
                    autoComplete={props.autoComplete}
                    placeholder={props.placeholder}
                                        
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={props.onClick}
                /> : 
                <Form.Control
                    size="sm"

                    readOnly={state.readOnly}
                    disabled={state.disabled}
                    isValid={state.isValid}
                    isInvalid={state.isInvalid}
                    value={state.value}

                    id={props.id}
                    as={props.as}
                    name={props.name}
                    type={props.type}
                    required={props.required}
                    maxLength={props.maxLength}
                    autoComplete={props.autoComplete}   
                    placeholder={props.placeholder}

                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={props.onClick}                 
                />;

        return  (
            <Form.Group hidden={props.hidden}>
                <CustomSpinner 
                    className="spinner-input" 
                    hidden={!state.loading} 
                />

                <CommonLabel 
                    id={props.id}
                    label={props.label}
                    required={props.required}
                    informationMessage={props.informationMessage}
                />
                
                <InputGroup>
                    <CustomInputGroupPrepend  
                        disabled={state.disabled} 
                        readOnly={state.readOnly} 
                        prepend={props.prepend}
                    />        
                    
                    {render}
                            
                    <CustomInputGroupAppend  
                        disabled={state.disabled} 
                        readOnly={state.readOnly} 
                        append={props.append}
                    />

                    <CheckHasFeedback 
                        value={state.value}
                        feedback={props.feedback}
                        isInvalid={state.isInvalid}
                        isValid={state.isValid}
                        required={props.required}
                    />
                </InputGroup>
                {
                    state.value && 
                    state.value.length > 0 && 
                    props.maxLength && 
                    props.maxLength > 1 && 
                    <Form.Text className="text-muted text-right">
                        {state.value.length + " / " + props.maxLength} 
                    </Form.Text>
                }
            </Form.Group>
        )
    }
}

BaseInput.defaultProps = {
    type: 'text',
    as: 'input',
    filterOperator: FilterOperator.Equals
}

export default BaseInput;