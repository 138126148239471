import React, { FunctionComponent, useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

const BaseFieldSet: FunctionComponent<{ legend: any, collapsible?: boolean }> = (props) => {
    const [collapsed, setCollapsed] = useState(true);

    return (
        <fieldset className="border px-3 rounded">
            <legend className="w-auto px-1">
                {
                    props.collapsible &&
                    <Button 
                        size="sm" 
                        onClick={() => setCollapsed(!collapsed)} 
                        variant="link"
                    >
                        <FontAwesomeIcon 
                            icon={collapsed ? 
                                    faAngleUp : 
                                    faAngleDown} 
                            size="lg" 
                        />
                    </Button>
                }
                {props.legend}
            </legend>
            <div style={{ display: collapsed ? 'block' : 'none' }}>
                {props.children}
            </div>
        </fieldset>
    );
}

BaseFieldSet.defaultProps = {
    collapsible: true
}

export default BaseFieldSet;