import React, { Component } from 'react';
import BaseGrid, { 
    ITableColumn, 
    IServerSidePagination, 
    IPagination 
} from '../grid';
import Loading from '../loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Util from "../util";
import LogService from '../../services/log-service';
import BaseNavBar from '../nav-bar';
import './style.scss';

export interface ILogProps {
    name: string,
    id: number,
    keys: string,
    data: Array<any>,
    moduleId?: number
}

interface ILogState {
    loading: boolean,
    tableColumn: ITableColumn[],
    data: Array<any>,
    pagination: IPagination,
    selectedNavigation: number
}

export default class Log extends Component<ILogProps, ILogState> {
    private getObjectValue = Util.getObjectValue;

    state: ILogState = {
        loading: true,
        tableColumn: [],
        data: [],
        pagination: {
            page: 1,
            limit: 10,
            pages: 0,
            records: 0,
            first: 0,
            last: 0
        },
        selectedNavigation: 1
    }

    async componentDidMount() {
        await this.getColumns();
    }

    getColumns = async () => {
        const { id, moduleId } = this.props;

        const result = await LogService.get({ 
            id,
            keys: this.createKey(),
            moduleId,
            page: 1,
            start: 0,
            limit: 1,
            sorter: ""
        });

        let tableColumn: ITableColumn[] = [];

        for(let prop in result.data.data[0]) {
            let firstLine = result.data.data[0][prop];

            if (firstLine && typeof firstLine === 'object')
                prop = this.getObjectValue(firstLine, "@Header")

            tableColumn.push({
                displayName: prop,
                name: prop,
                sortable: true
            });
        }

        this.setState({
            tableColumn,
            loading: false
        });
    }

    createKey = () => {
        const { keys, data } = this.props;

        let delimiter = '|',
            keysArray = keys.split(delimiter);
        
        keysArray = keysArray.map( value => {
            value = value.trim();
            return `${value}=${this.getObjectValue(data[this.state.selectedNavigation - 1], value)}`;
        });

        return keysArray.join(delimiter);
    }

    loadData = async (iServerSidePagination: IServerSidePagination) => {
        const { 
            page, 
            itemsPerPage, 
            sorter 
        } = iServerSidePagination;

        const { 
            id, 
            moduleId 
        } = this.props;

        const result = await LogService.get({ 
            id,
            keys: this.createKey(),
            moduleId,
            page,
            start: (page * itemsPerPage - itemsPerPage),
            limit: itemsPerPage,
            sorter: JSON.stringify(sorter)
        });
        
        result.data.data.map((value: any) => {
            for(let prop in value) {
                if (value[prop] && typeof value[prop] === 'object')
                    value[prop] = this.getObjectValue(value[prop], "@Value");
            }

            return value;
        });

        this.setState({
           data: result.data.data,
           pagination: {
               ...this.state.pagination, 
                page,
                pages:  (result.data.total / itemsPerPage) < 1 ? 
                            1 : 
                            (result.data.total / itemsPerPage), 
                records: result.data.total
           }
        });
    }

    callbackOnSelectedNavigation = (selectedNavigation: number) => {
        this.setState({
            loading: true,
            selectedNavigation
        }, async () => {
            await this.getColumns();
        });
    }

    render() {        
        return <>           
            <Loading hidden={!this.state.loading} />
            
            {this.props.data.length > 1 && <BaseNavBar name={this.props.name} data={this.props.data} callbackOnSelected={this.callbackOnSelectedNavigation}  />}

            {!this.state.loading && this.state.tableColumn.length === 0 &&
            <div className="alert alert-warning mt-2 px-2" role="alert">
                <FontAwesomeIcon className="text-warning" size="lg" icon={faInfoCircle} /> Nenhum registro encontrado
            </div>}
            
            {this.state.tableColumn.length > 0 && 
            <BaseGrid
                mode={
                    {
                        type: "callback",
                        callback: {
                            data: this.state.data,
                            pagination: this.state.pagination,
                            handleServerSide: this.loadData
                        }
                    }
                }
                name={`${this.props.name}-log-grid`} 
                columns={this.state.tableColumn}
            />
            }
        </>;
    }
}