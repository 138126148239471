import React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.scss';

class Loading extends React.Component<{hidden?: boolean}> {
    static defaultProps: {hidden?: boolean};

    render() {
        return (
            <Spinner 
                hidden={this.props.hidden}
                className="loading"
                variant="primary" 
                animation="border" 
            />
        );
    }
}

Loading.defaultProps = {
    hidden: false
}

export default Loading;