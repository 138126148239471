import BaseService from './base-service'

const baseEndpoint: string = window.commonService.base;
const baseService = new BaseService();
baseService.setBaseEndpoint(baseEndpoint);

class ZipCodeService {
    static setBaseEndpoint = (endpoint: string) => {
        baseService.setBaseEndpoint(endpoint);
    }
    
    static get = (zipCode: string) => {
        return baseService.get(`${window.commonService.address}/${zipCode}`);
    }

    static getAddressType = () => {
        return baseService.get(window.commonService.addressType)
    }
}

export default ZipCodeService;