import React, { RefObject } from "react";
import CepInput, { IAddress, MapsWindowButton } from "../../input/cep";
import BaseFieldSet from "../../fieldset";
import BaseInput from "../../input/input";
import BaseSelect from "../../input/select";
import { Form, Col } from "react-bootstrap";
import BaseDropdown from "../../input/dropdown";

export interface IAddressInputGroupProps {
    id?: {
        name: string,
        innerRef: RefObject<any>
    },
    cep: {
        name: string,
        innerRef: RefObject<any>
    },
    street: {
        name: string,
        innerRef: RefObject<any>
    },
    number: {
        name: string,
        innerRef: RefObject<any>
    },
    complement: {
        name: string,
        innerRef: RefObject<any>
    },
    district: {
        name: string,
        innerRef: RefObject<any>
    },
    city: {
        name: string,
        innerRef: RefObject<any>
    },
    state: {
        name: string,
        innerRef: RefObject<any>
    },
    type: {
        name: string,
        innerRef: RefObject<any>
    }
}

export default class AddressInputGroup extends React.Component<IAddressInputGroupProps, { address: IAddress}> {
    state = {
        address: {
            zipCode: '',
            street: '',
            streetNumber: '',
            complement: '',
            district: '', 
            city: '',
            state: '',
        }
    }

    private zipCode = this.props.cep.innerRef;
    private street = this.props.street.innerRef;
    private number = this.props.number.innerRef;
    private complement = this.props.complement.innerRef;
    private district = this.props.district.innerRef;
    private city = this.props.city.innerRef;
    private addressState = this.props.state.innerRef;
    private addressType = this.props.type.innerRef;
    
    callbackAddress = (address: IAddress) => {
        this.setState((state) => ({
            address: {
                complement: state.address.complement,
                streetNumber: state.address.streetNumber,
                ...address
            }
        }));

        if (address.street) {
            this.setValue(this.street, address.street);
            this.setReadOnly(this.street, true);
            this.setIsInvalid(this.street, false);
        } else {
            this.setValue(this.street, '');
            this.setReadOnly(this.street, false);
        }

        if (address.district) {
            this.setValue(this.district, address.district);
            this.setReadOnly(this.district, true);
            this.setIsInvalid(this.district, false);
        } else {
            this.setValue(this.district, '');
            this.setReadOnly(this.district, false);
        }

        if (address.city) {
            this.setValue(this.city, address.city);
            this.setDisabled(this.city, true);
            this.setIsInvalid(this.city, false);
        } else {
            this.setValue(this.city, '');
            this.setDisabled(this.city, false);
        }

        if (address.state) {
            this.setValue(this.addressState, address.state);
            this.setDisabled(this.addressState, true);
            this.setIsInvalid(this.addressState, false);
        } else {
            this.setValue(this.addressState, '');
            this.setDisabled(this.addressState, false);
        }
    }

    setDisabled = (ref: React.RefObject<any>, disabled: boolean) => {
        if (ref.current) ref.current.setDisabled(disabled);
    }

    setReadOnly = (ref: React.RefObject<any>, readOnly: boolean) => {
        if (ref.current) ref.current.setReadOnly(readOnly);
    }

    setIsInvalid = (ref: React.RefObject<any>, isInvalid: boolean) => {
        if (ref.current) ref.current.setIsInvalid(isInvalid);
    }

    setValue = (ref: React.RefObject<any>, value: string) => {
        if (ref.current) ref.current.setValue(value);
    }

    onChange = (event: any) => {
        this.setState({
            address: {
                ...this.state.address,
                streetNumber: event.target.value
            }
        });
    }

    render() {
        return (
        <BaseFieldSet 
            legend={
                <MapsWindowButton 
                    label='Endereço' 
                    address={this.state.address}
                />
            }>
                <Form.Row>
                    {
                        this.props.id && 
                        <Col lg="2" xl="2">
                            <BaseInput
                                {...this.props.id}
                                label="Id"
                                readOnly
                                identifier
                                ref={this.props.id.innerRef}
                            />
                        </Col>
                    }
                    <Col lg="3" xl="2">
                        <CepInput 
                            {...this.props.cep}
                            type="text"
                            callbackAddress={this.callbackAddress}
                            required
                            ref={this.zipCode}
                            showMapsButton
                        />
                    </Col>
                    <Col>
                        <BaseInput
                            {...this.props.street}
                            type="text"
                            label="Logradouro"
                            required
                            ref={this.street}
                        />
                    </Col>
                    <Col lg="2" xl="2">
                        <BaseInput 
                            {...this.props.number}
                            type="text"
                            label="Número"
                            required
                            onChange={this.onChange}
                            maxLength={40}
                            ref={this.number}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col lg="6" xl="6">
                        <BaseInput 
                            {...this.props.complement}
                            type="text"
                            label="Complemento"
                            maxLength={40}
                            ref={this.complement}
                        />
                    </Col>
                    <Col lg="6" xl="6">
                        <BaseInput 
                            {...this.props.district}
                            type="text"
                            label="Bairro"
                            required
                            ref={this.district}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col lg="4" xl="4">
                        <BaseDropdown
                            {...this.props.city}
                            type="number"
                            label="Cidade"
                            required
                            ref={this.city}
                            endpoint={`${window.commonService.base}${window.commonService.city}`}
                            valueProperty="id"
                            labelProperty="name"
                        />
                    </Col>
                    <Col lg="4" xl="4">
                        <BaseDropdown 
                            {...this.props.state}
                            type="number"
                            label="Estado"
                            required
                            ref={this.addressState}
                            endpoint={`${window.commonService.base}${window.commonService.state}`}
                            valueProperty="id"
                            labelProperty="name"
                        />
                    </Col>
                    <Col lg="4" xl="4">
                        <BaseSelect 
                            {...this.props.type}
                            type="number"
                            label="Tipo"
                            required
                            ref={this.addressType}
                            endpoint={`${window.commonService.base}${window.commonService.addressType}`}
                        />
                    </Col>
                </Form.Row>
            </BaseFieldSet>
        );
    }
}   