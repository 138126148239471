import React, { ReactElement } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './style.scss';

export enum TypesBaseModal {
    create = "Novo",
    read = "Detalhar",
    update = "Editar",
    delete = "Excluir",
    search = "Pesquisar",
    log = "Log",
    export = "Exportar"
}

export type IBaseModalProps = {
    content: object | undefined,
    title?: string,
    type?: TypesBaseModal,
    show: boolean,
    size?: "sm" | "lg" | "xl"
    callbackAfterClose(): void | undefined,
    data?: Array<any>,
    name: string
} & Partial<DefaultProps>

type DefaultProps = Readonly<typeof defaultProps>

const defaultProps = {
    show: false
}

export default class BaseModal extends React.Component<IBaseModalProps, { show: boolean }> {
    public static defaultProps = defaultProps;

    state = {
        show: this.props.show
    }

    handleClose = () => this.setState({ show: false }, () => {
        if (this.props.callbackAfterClose)
            this.props.callbackAfterClose();
    });

    render() {
        const { show, size, type, title, data, name } = this.props;
        
        return show ? 
            <Modal 
                backdrop="static" 
                keyboard={false} 
                show={show} 
                onHide={this.handleClose} 
                size={size}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            type || title
                        }
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {
                        React.cloneElement(
                            this.props.content as ReactElement, 
                            { 
                                data, 
                                name
                            }
                        )
                    }
                </Modal.Body>
                
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        size="sm" 
                        onClick={this.handleClose}
                    >
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal> : null
    }
}