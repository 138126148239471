import React, { FunctionComponent } from 'react';
import { FilterOperator } from '../../grid';
import { Form, InputGroup, Spinner, ButtonProps, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.scss';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

export const REQUIRED_MESSAGE: string = 'Preenchimento obrigatório';

export interface ICommonInputProps {
    id?: string,
    name: string,
    label: string,
    mapping?: string,
    readOnly?: boolean,
    disabled?: boolean,
    hidden?: boolean,
    loading?: boolean,
    isValid?: boolean,
    isInvalid?: boolean,
    required?: boolean,
    filterOperator?: FilterOperator,
    excludeFilter?: boolean,
    informationMessage?: string,
    feedback?: {
        invalid: string,
        valid?: string
    },
    prepend?: {
        icon: any,
        buttonProps: ButtonProps,
        onClick?(event: any): Promise<void> | void | undefined,
        hideWhenRead?: boolean
    },
    append?: {
        icon: any,
        buttonProps: ButtonProps,
        onClick?(event: any): Promise<void> | void | undefined,
        hideWhenRead?: boolean
    },
    onBlur?(event: any): Promise<void> | void | undefined,
    onChange?(event: any): Promise<void> | void | undefined,
    onClick?(event: any): Promise<void> | void | undefined,
    onReset?(): void,
    onSetValue?(value: any): void,

    max?: number,
    min?: number,
    step?: number
}

export interface ICommonInputState {
    hidden?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    isInvalid?: boolean,
    isValid?: boolean,
    excludeFilter?: boolean
}

export const CommonLabel: FunctionComponent<Pick<ICommonInputProps, 'id' | 'label' | 'required' | 'informationMessage'>> = (props) => {
    return (
        <Form.Label
            htmlFor={props.id}
        >
            {props.label}
            {
                props.required &&
                <RequiredIcon />
            }

            {
                props.informationMessage &&
                <Information
                    id={`${props.id}-information-message`}
                    message={props.informationMessage}
                />
            }
        </Form.Label>
    )
}

export const RequiredIcon: FunctionComponent = () => {
    return (
        <span className="text-danger">&nbsp;
            <strong>*</strong>
        </span>
    )
}

export const Information: FunctionComponent<{ id: string, message: string }> = (props) => {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Popover 
                    id={props.id} 
                    className="mb-2"
                >
                    <Popover.Title as="h6">
                        <FontAwesomeIcon
                            className="text-info"
                            icon={faInfo}
                        /> Informação
                        </Popover.Title>
                    <Popover.Content>
                        {props.message}
                    </Popover.Content>
                </Popover>
            }
        >
            <span>
                <FontAwesomeIcon
                    className="text-info ml-2"
                    icon={faInfo}
                />
            </span>
        </OverlayTrigger>
    )
}

export const CustomSpinner: FunctionComponent<{ hidden: boolean, className: string }> = (props) => {
    return props.hidden ? null : (
        <Spinner
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
            variant="primary"
            className={props.className}
            hidden={props.hidden}
        />
    )
}

export const CustomInputGroupPrepend: FunctionComponent<Pick<ICommonInputProps, 'readOnly' | 'disabled' | 'prepend'>> = (props) => {
    if (!props.prepend! || props.prepend!.hideWhenRead! && (props.readOnly || props.disabled)) 
        return null;

    return (
        <InputGroup.Prepend className="custom-input mr-0">
            <Button 
                size="sm" 
                onClick={props.prepend!.onClick} 
                variant={props.prepend!.buttonProps.variant}
                disabled={props.prepend!.buttonProps.disabled}>
                    <FontAwesomeIcon icon={props.prepend!.icon} />
            </Button>
        </InputGroup.Prepend>
    )
}

export const CustomInputGroupAppend: FunctionComponent<Pick<ICommonInputProps, 'readOnly' | 'disabled' | 'append'>> = (props) => {
    if (!props.append! || props.append!.hideWhenRead! && (props.readOnly || props.disabled)) 
        return null;

    return (
        <InputGroup.Append className="custom-input ml-0">
            <Button 
                size="sm" 
                onClick={props.append!.onClick} 
                variant={props.append!.buttonProps.variant}
                disabled={props.append!.buttonProps.disabled}>
                    <FontAwesomeIcon icon={props.append!.icon} />
            </Button>
        </InputGroup.Append>
    )
}

export interface ICheckHasFeedbackProps extends Pick<ICommonInputProps , 'isInvalid' | 'isValid' | 'required' | 'feedback'> {
    value: any
}

export const CheckHasFeedback: FunctionComponent<ICheckHasFeedbackProps> = (props) => {
    if (props.isInvalid) {
        let feedback = '';
        
        if (props.required && !props.value)
            feedback = REQUIRED_MESSAGE;
        else if (props.feedback && props.feedback.invalid)
            feedback = props.feedback.invalid;
        else 
            return null;

        return <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
    }
    else if (props.isValid) {
        let feedback = '';

        if (props.feedback && props.feedback.valid)
            feedback = props.feedback.valid;
        
        return <Form.Control.Feedback type="valid">{feedback}</Form.Control.Feedback>
    }
    else
        return null;
}

export default function CommonInput<P extends ICommonInputProps>(
    WrappedComponent: React.ComponentType<P>
) : React.ComponentClass<P> {
    class CommonInput extends React.Component<P, ICommonInputState> {
        private wrappedComponentRef = React.createRef<any>();

        state: ICommonInputState = {
            disabled: this.props.disabled,
            readOnly: this.props.readOnly,
            hidden: this.props.hidden,
            isValid: this.props.isValid,
            isInvalid: this.props.isInvalid
        }
        
        setDisabled = (disabled: boolean) => this.setState({ disabled });
        setReadOnly = (readOnly: boolean) => this.setState({ readOnly });
        setHidden = (hidden: boolean) => this.setState({ hidden });
        setIsInvalid = (isInvalid: boolean) => this.setState({ isInvalid });
        setIsValid = (isValid: boolean) => this.setState({ isValid });
        setValue = (value: any, rawValue: any = undefined) => this.wrappedComponentRef.current.setValue(value, rawValue);
        getValue = () => this.wrappedComponentRef.current.state.value;
        getRawValue = () => this.wrappedComponentRef.current.state.rawValue;

        reset = () => {
            this.setState({
                isInvalid: this.props.isInvalid ? this.props.isInvalid : false,
                isValid: this.props.isValid ? this.props.isValid : false,
                readOnly: this.props.readOnly ? this.props.readOnly : false,
                disabled: this.props.disabled ? this.props.disabled : false,
                hidden: this.props.hidden ? this.props.hidden : false,
            }, () => {
                this.wrappedComponentRef.current.reset();
            });
        }

        onChange = (event: any) => {
            let value = event.target.value;
            const { onChange } = this.props;
    
            this.checkIfIsInvalid(value);
            if (onChange) onChange(event);
        }

        onBlur = (event: any) => {
            let value = event.target.value;
            const { onBlur } = this.props;
    
            this.checkIfIsInvalid(value);
            if (onBlur) onBlur(event);
        }

        checkIfIsInvalid = (value: any) => {
            const { required } = this.props;

            if (required && value || !value) 
                this.setState({ isInvalid: false });
        }

        render() {
            return (
                <WrappedComponent
                    ref={this.wrappedComponentRef}
                    disabled={this.state.disabled}
                    readOnly={this.state.readOnly}
                    hidden={this.state.hidden}
                    isInvalid={this.state.isInvalid}
                    isValid={this.state.isValid}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    {...this.props}
                />
            );
        }
    }

    return CommonInput;
}