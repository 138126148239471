import React, { RefObject, FunctionComponent, useContext } from "react";
import {
  Form,
  Button,
  Spinner,
  Popover,
  Overlay,
  Toast
} from "react-bootstrap";
import Util from "../util";
import BaseModal, { TypesBaseModal } from "../modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faSearch,
  faSave,
  faInfo
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { IFilter, FilterOperator } from "../grid";
import BaseNavBar from "../nav-bar";
import FormContext, { FormProvider } from "../context/form";

import "./style.scss";
import BaseDropdown from "../input/dropdown";

export interface IBaseFormProps {
  refs: Array<RefObject<any>>;
  data?: Array<any>;
  disabled?: boolean;
  readOnly?: boolean;
  type?: TypesBaseModal;
  baseModalRef?: RefObject<BaseModal>;
  filter?: IFilter[];
  callbackFilter?(filter: IFilter[]): Promise<void> | void | undefined;
  callbackRemoveSelectedItems?(): void;
  name?: string;
  endpoint?: string;
  dependentProperty?: {
    name: string;
    value: any;
  };
  identifiers?: string[];
  beforeSubmit?(): void;
  afterSubmit?(success: any, data: any, error: any, messages: any): void;
}

interface IBaseFormState {
  data?: Array<any>;
  submited: boolean;
  success: boolean;
  error?: {
    code: number;
    message: string;
  };
  messages?: Array<{
    index: number;
    message: string;
  }>;
  filter: IFilter[];
  loading: boolean;
  confirmPopover: boolean;
  selectedNavigation: number;
}

// implementar a possibilidade de definir um escopo
const Scope: FunctionComponent<{ path: string }> = ({ path, children }) =>
{
  const { scopePath, ...formContextProps } = useContext(FormContext);

  return (
    <FormProvider
      value={{
        ...formContextProps,
        scopePath: scopePath.concat(scopePath ? `.${path}` : path)
      }}
    >
      {children}
    </FormProvider>
  );
};

class BaseForm extends React.Component<IBaseFormProps, IBaseFormState>
{
  static defaultProps: IBaseFormProps;
  saveButtonRef = React.createRef<any>();
  deleteButtonRef = React.createRef<any>();

  private type = this.props.type;

  state: IBaseFormState = {
    data: this.props.data,
    submited: false,
    success: false,
    filter: [],
    loading: false,
    confirmPopover: false,
    selectedNavigation: 1
  };

  componentDidMount = () => this.initializeForm();

  initializeForm = () =>
  {
    const { checkHasFilter, setDisabled, setValue, state, type } = this;
 
    if (type === TypesBaseModal.read) setDisabled(true);

    if (type === TypesBaseModal.read || type === TypesBaseModal.update)
      setValue();

    if (state.data && state.data.length > 1) {
      this.setState({
        data: state.data.map(value => ({ ...value, updated: false }))
      });
    }

    checkHasFilter();
  };

  checkHasFilter = () =>
  {
    let filter = this.props.filter;

    if (filter) {
      this.setState({ filter });

      if (this.type === TypesBaseModal.search) {
        this.props.refs.forEach(value => {
          let instance = value.current;
          if (instance) {
            let nameField = instance.props.name;

            filter!.forEach(value => {
              if (value.property === nameField) {
                instance!.setValue(
                  (value.mask && Util.conformToMask(value.value, value.mask)) ||
                    value.value,
                  value.display
                );
              }
            });
          }
        });
      }
    }
  };

  validateForm = (): boolean =>
  {
    let hasFieldsIsInvalid = false;

    this.props.refs.forEach(value => {
      if (value.current) {
        if (value.current.state.isInvalid) hasFieldsIsInvalid = true;
        else if (value.current.props.required && !value.current.getValue()) {
          value.current.setIsInvalid(true);
          hasFieldsIsInvalid = true;
        } else if (value.current.props.required && value.current.getValue())
          value.current.setIsInvalid(false);
      }
    });

    return !hasFieldsIsInvalid;
  };

  checkIsValid = () => this.setState({ confirmPopover: this.validateForm() });

  notSubmitForm = (event: any) => {
    event.stopPropagation();
    this.setState({ confirmPopover: false });
  };

  getJsonData = () => {
    let json: { [k: string]: any } = {};

    this.props.refs.forEach(value => {
      if (value.current) {
        let stateValue = value.current.props.isDate
          ? value.current.getRawValue()
          : value.current.getValue();

        if (stateValue !== "" && stateValue !== undefined) {
          if (value.current.props.isCurrency) {
            stateValue = value.current.getValue();
          } else if (
            value.current.props.mask &&
            !value.current.props.isEmail &&
            !value.current.props.isDate
          ) {
            stateValue = Util.onlyAlphaCharacters(stateValue);
          } else if (
            value.current.props.type === "number" ||
            value.current.props.identifier
          ) {
            stateValue = stateValue ? parseInt(stateValue) : 0;
          }
        } else {
          if (
            value.current.props.type === "number" ||
            value.current.props.identifier
          )
            stateValue = 0;
          else stateValue = null;
        }

        json[value.current.props.name] = stateValue;
      }
    });

    if (this.props.identifiers)
      this.props.data!.forEach(value => {
        this.props.identifiers!.forEach(id => (json[id] = value[id]));
      });

    if (this.props.dependentProperty)
      json = {
        ...json,
        [this.props.dependentProperty.name]: this.props.dependentProperty.value
      };

    return json;
  };

  handleSubmit = async (event: any) =>
  {
    event.stopPropagation();

    if (this.validateForm())
    {
      this.setState({
        confirmPopover: false,
        loading: true,
        submited: false
      });

      let result = null;

      if (this.type === TypesBaseModal.create)
        result = await Axios.post(this.props.endpoint!, this.getJsonData());
      else if (this.type === TypesBaseModal.update)
        result = await Axios.put(this.props.endpoint!, this.getJsonData());
      else
        result = await Axios.delete(this.props.endpoint!, {
          data: this.getJsonData()
        });

      const { success, data, error, messages } = result.data;
      
      if(this.props.afterSubmit)
        this.props.afterSubmit(success, data, error, messages);

      if (success)
      {
        this.setDisabled(true);

        this.props.refs.forEach(value =>
        {
          if (value.current && value.current.props.identifier)
            value.current.setValue(data[value.current.props.name]);
        });

        this.setState({ success: true, messages: messages }, () =>
                      {
                        if (this.props.callbackFilter)
                          this.props.callbackFilter(this.state.filter);
                        if (this.props.callbackRemoveSelectedItems)
                          this.props.callbackRemoveSelectedItems();
                      });
      }
      else
      {
        this.setState({
          success: false,
          error,
          messages
        });
      }

      this.setState({
        submited: true,
        loading: false
      });
    }
  };

  forEachRefCallMethod = (method: string, params: any[] = []) =>
  {
    return this.props.refs.forEach(value =>
    {
      if (value.current)
      {
        if (params.length > 0)
          value.current[method](params); 
        else
          value.current[method]("");
      }
    });
  };
  
  handleReset = () =>
  {
    this.forEachRefCallMethod("reset");

    if (this.type === TypesBaseModal.search)
    {
      if (this.state.filter.length === 0)
        return;

      this.setState({ filter: [] },
                    () => this.props.callbackFilter && this.props.callbackFilter([]));
    }
  };

  handleSearch = () =>
  {
    if (!this.validateForm())
      return;

    let filter: IFilter[] = [];

    this.props.refs.forEach(value =>
    {
      let currentRef = value.current;
      
      if (currentRef && (currentRef.getValue() !== "" && !currentRef.props.excludeFilter))
      {
        filter.push({
          property: currentRef.props.name,
          operator: currentRef.props.filterOperator || FilterOperator.Equals,
          value: currentRef.getValue(),
          mask: currentRef.props.mask,
          label: currentRef.props.label,
          display: currentRef.getDisplayValue
            ? currentRef.getDisplayValue()
            : currentRef.getRawValue
            ? currentRef.getRawValue()
            : currentRef.getValue()
        });
      }
    });

    this.setState({ filter },
                  () => this.props.callbackFilter && this.props.callbackFilter(filter));

    if (this.props.baseModalRef && this.props.baseModalRef.current)
      this.props.baseModalRef.current.handleClose();
  };

  setReadOnly = (readOnly: boolean) =>
    this.forEachRefCallMethod("setReadOnly", [readOnly]);

  setDisabled = (disabled: boolean) =>
     this.forEachRefCallMethod("setDisabled", [disabled]);

  setValue = () =>
  {
    this.props.refs.forEach(value =>
    {
      let instance = value.current;

      if (instance)
      {
        const { name, mask, isDate, mapping } = instance.props;
        const propertyName = mapping || name;

        let data = this.props.data![this.state.selectedNavigation - 1],
            objectValue = Util.getObjectValueFromObject(data, propertyName);

        if (objectValue !== null)
        {
          if (isDate)
            objectValue = new Date(objectValue);
          else if (mask && (Array.isArray(mask) || typeof mask === "function"))
            objectValue = Util.conformToMask(objectValue, mask);
        }
        else
          objectValue = "";

        if (isDate)
          instance.props.handleChange(objectValue); 
        else if (instance instanceof BaseDropdown)
          instance.loadValue(objectValue);
        else
          instance.setValue(objectValue);
      }
    });
  };

  getStatusMessage = (): string =>
  {
    const { type } = this.props;

    if (type === TypesBaseModal.create) return "inclusão";

    if (type === TypesBaseModal.update) return "alteração";

    if (type === TypesBaseModal.delete) return "exclusão";

    return "";
  };

  saveButton = () =>
  {
    return (
      <Button
        id={`${this.props.name}-form-btn-save`}
        size="sm"
        ref={this.saveButtonRef}
        onClick={this.checkIsValid}
        disabled={this.state.loading}
      >
        <FontAwesomeIcon icon={faSave} /> Salvar
        {this.state.loading && (
          <Spinner
            className="ml-2"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {this.confirmPopover(this.saveButtonRef)}
      </Button>
    );
  };

  clearButton = () =>
  {
    const { props, handleReset } = this;

    return (
      <Button
        id={`${props.name}-form-btn-clear`}
        onClick={handleReset}
        variant="secondary"
        size="sm"
        disabled={this.state.loading}
      >
        <FontAwesomeIcon icon={faTrash} /> Limpar
      </Button>
    );
  };

  deleteButton = () =>
  {
    return (
      <Button
        id={`${this.props.name}-form-btn-delete`}
        size="sm"
        variant="danger"
        ref={this.deleteButtonRef}
        onClick={this.checkIsValid}
        disabled={this.state.loading}
      >
        <FontAwesomeIcon icon={faSave} /> Salvar
        {this.state.loading && (
          <Spinner
            className="ml-2"
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {this.confirmPopover(this.deleteButtonRef)}
      </Button>
    );
  };

  toastStatus = () =>
  {
    const { submited, success, error, messages } = this.state;

    return (
      <Toast
        show={submited}
        onClose={() => this.setState({ submited: false, error: undefined, messages: [] })}
        style={{
          position: "absolute",
          top: "1rem",
          right: "1rem"
        }}
      >
        <Toast.Header>
          <span className={`px-2 mr-1 ${success ? "bg-success" : "bg-danger"}`}>
            <FontAwesomeIcon color="white" icon={faInfo} />
          </span>
          <strong className="mr-auto">Informação</strong>
        </Toast.Header>
        <Toast.Body>
          <p>
            Registro processado com{" "}
            <strong>{success ? "sucesso" : "erro"}</strong>!
          </p>
          {error &&
            messages &&
            messages.map((value, i) => (
              <p key={i} className="text-danger">
                {value.index + 1} - {value.message}
              </p>
            ))}
        </Toast.Body>
      </Toast>
    );
  };

  confirmPopover = (buttonRef: RefObject<any>) =>
  {
    let message = this.props.data!.length > 1
                    ? "dos registros?"
                    : "do registro?";

    return (
      <Overlay
        target={buttonRef.current}
        show={this.state.confirmPopover}
        placement="right"
      >
        <Popover
          id={`${this.props.name}-form-popover`}
          className="m-3 shadow-sm"
        >
          <Popover.Title as="h6">
            <FontAwesomeIcon icon={faInfo} /> Atenção!
          </Popover.Title>
          <Popover.Content>
            <p>
              Você confirma a <strong>{this.getStatusMessage()}</strong>{" "}
              {message}
            </p>
            <Button
              id={`${this.props.name}-form-popover-btn-no`}
              onClick={this.notSubmitForm}
              size="sm"
              variant="secondary"
            >
              Não
            </Button>
            &nbsp;
            <Button
              id={`${this.props.name}-form-popover-btn-yes`}
              onClick={(event: any) =>
              {
                if (this.props.beforeSubmit)
                  this.props.beforeSubmit();
                
                this.handleSubmit(event);
              }}
              size="sm"
            >
              Sim
            </Button>
          </Popover.Content>
        </Popover>
      </Overlay>
    );
  };

  callBackOnSelectedNavigationBar = (selectedNavigation: number): void =>
    this.setState({ selectedNavigation }, this.initializeForm);

  navigationBar = () =>
  {
    const { data, name } = this.props;

    if (data && data.length > 1)
      return (
        <BaseNavBar
          data={data}
          name={name!}
          callbackOnSelected={this.callBackOnSelectedNavigationBar}
        />
      );

    return null;
  };

  deleteContent = () =>
  {
    const { data } = this.props;

    let message = data!.length === 1
                    ? "O item selecionado será excluído"
                    : "Os itens selcionados serão excluídos";

    return (
      <>
        <p>{message}</p>
        <div className="mt-2 text-left form-buttons">{this.deleteButton()}</div>
      </>
    );
  };

  createContent = () =>
  {
    const { state, clearButton, saveButton, baseForm } = this;

    let additionalContent = (
      <div className="mt-2 text-left form-buttons">
        {!state.success && clearButton()}
        {!state.success && saveButton()}
      </div>
    );

    return baseForm(additionalContent);
  };

  updateContent = () =>
  {
    const { state, saveButton, baseForm } = this;

    let additionalContent = (
      <div className="mt-2 text-left form-buttons">
        {!state.success && saveButton()}
      </div>
    );

    return baseForm(additionalContent);
  };

  readContent = () =>
  {
    return (
      <>
        {this.navigationBar()}
        {this.baseForm(null)}
      </>
    );
  };

  searchContent = () =>
  {
    const { props, clearButton, handleSearch, baseForm } = this;

    let additionalContent = (
      <div className="mt-2 text-left form-buttons">
        {clearButton()}
        <Button
          id={`${props.name}-form-btn-search`}
          size="sm"
          onClick={handleSearch}
        >
          <FontAwesomeIcon icon={faSearch} /> Pesquisar
        </Button>
      </div>
    );

    return baseForm(additionalContent);
  };

  baseForm = (addtionalContent: any) =>
  {
    const { props } = this;
    return (
      <Form noValidate>
        <p className="text-danger text-right m-0 p-0">
          &nbsp;
          <strong>*</strong> Obrigatório
        </p>
        {props.children}
        {addtionalContent}
      </Form>
    );
  };

  getContent = () =>
  {
    const { type } = this.props;

    if (type === TypesBaseModal.create)
      return this.createContent();
    if (type === TypesBaseModal.update)
      return this.updateContent();
    else if (type === TypesBaseModal.read)
      return this.readContent();
    else if (type === TypesBaseModal.search)
      return this.searchContent();
    else if (type === TypesBaseModal.delete)
      return this.deleteContent();
    else return null;
  };

  render()
  {
    const { submited } = this.state;

    return (
      <>
        {this.getContent()}
        {submited && this.toastStatus()}
      </>
    );
  }
}

BaseForm.defaultProps = {
  data: [],
  refs: []
};

export default BaseForm;
